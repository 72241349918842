<template>
    <crud-index-component v-bind="$data">
        <template slot="header">
            {{ $t('crud.payment_methods._plural') }}
        </template>
    </crud-index-component>
</template>

<script>
    import CrudIndexComponent from "../crud/Index";
    import {config} from '../crud/mixin_config';

    export default {
        components: {CrudIndexComponent},
        data() {
            return {
                crud: config('payment_methods'),
                fields: [
                    {
                        title: this.$t('crud.id'),
                        name: 'id',
                        sortField: 'id'
                    },
                    {
                        title: this.$t('crud.name'),
                        name: 'name',
                        sortField: 'name'
                    },
                    {
                        title: this.$t('crud.payment_methods.description'),
                        name: 'description',
                        sortField: 'description'
                    },
                    {
                        title: this.$t('crud.updated_at'),
                        name: 'updated_at',
                        sortField: 'updated_at'
                    },
                    {
                        title: this.$t('crud.actions'),
                        name: 'slot-actions'
                    }
                ],
            };
        },
    }
</script>
